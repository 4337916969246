<template>
  <section class="goods_detail">
    <NavBar />
    <Header type="personalCenter" />
    <div class="content box p30 pb200">
      <div class="goods_info frfsfs">
        <div class="left mr30">
          <div class="goods_picture">
            <img :src="goodsDetail.picture">
          </div>
        </div>
        <div class="right">
          <div class="goods_name">
            {{goodsDetail.goodsName}}
          </div>
          <div class="price mt30 fccfs p15">
            <div class="straight_pi_jia frfsc">
              {{unionFlag == 1 ? '联盟价' : regularFlag == 2 ? '批发价' : '折扣价'}}￥{{regularFlag == 2 ? goodsDetail.tradePrice : goodsDetail.extendPrice}}
              <div class="discount ml5">{{regularFlag == 2 ? goodsDetail.discountTrade : goodsDetail.discountRate}}折</div>
              <div class="pricing ml5" v-if="regularFlag != 2">定价<span>￥{{goodsDetail.basePrice}}</span></div>
            </div>
            <div class="straight_pi mt10 frfsc" v-if="regularFlag != 2">
              ￥{{goodsDetail.tradePrice}}
              <img class="ml5" src="~assets/images/straight-pi-jia.png">
              <div class="ml5">订单满{{fullAmount}}元或金卡会员可享</div>
            </div>
            <div class="pricing mt5" v-else>
              定价<span>￥{{goodsDetail.basePrice}}</span>
            </div>
          </div>
          <div class="operation mt50 frsbc">
            <div class="number">
              数量<el-input-number class="input-number150 ml5" v-model="goodsDetail.number" :min="1"></el-input-number>
            </div>
            <div class="add_purchase_order" :class="{'no_inventory': goodsDetail.validNum <= 0}" @click="addPurchaseOrder">加入采购单<img src="~assets/images/no-inventory1.png" v-if="goodsDetail.validNum <= 0"></div>
          </div>
        </div>
      </div>
      <div class="rich_text">
        <div class="title">
          <img src="~assets/images/book-detail.png">
        </div>
        <div class="body p15" v-html="goodsDetail.appGoodsIntroduce"></div>
      </div>
    </div>
    <Footer
      type="goodsDetail"
    />
  </section>
</template>

<script>
  import NavBar from 'components/NavBar/index.vue';
  import Header from 'components/Header/index.vue';
  import Footer from 'components/Footer/index.vue';
  import {getRegularFlag} from 'utils/user.js';
  import {
    getGoodsDetail
  } from 'api/goodsDetail.js';
  import {
    getTopUp,
    addPurchaseOrder
  } from 'api/purchaseOrder.js';

  export default {
    components: {
      NavBar,
      Header,
      Footer
    },

    created() {
      if(this.$route.params.goodsCode) {
        this.getTopUp();
        this.getGoodsDetail(this.$route.params.goodsCode);
      }else {
        this.$router.push({
          name: 'home'
        });
      }
    },

    data() {
      return {
        regularFlag: getRegularFlag(),
        goodsDetail: {},
        fullAmount: 0
      }
    },

    computed: {
      unionFlag() {
        return this.$store.state.user.unionFlag;
      },
      token() {
        return this.$store.state.user.token;
      }
    },

    methods: {
      getTopUp() {
        getTopUp().then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.fullAmount = res.data;
        });
      },
      getGoodsDetail(goodsCode) {
        getGoodsDetail({
          goodsCode
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.goodsDetail = res.data;
          this.$set(this.goodsDetail, 'number', 1);
        });
      },
      addPurchaseOrder() {
        if(this.goodsDetail.validNum <= 0) {
          this.$message.warning('该商品暂无库存！');
          return;
        }
        if(!this.token) {
          this.$router.replace({
            name: 'login'
          });
          return;
        }
        addPurchaseOrder({
          goodsCode: this.goodsDetail.goodsCode,
          number: this.goodsDetail.number
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success('加入采购单成功！');
          this.$store.dispatch('getPurchaseOrderData');
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .goods_detail {
    .content {
      .goods_info {
        .left {
          width: 300px;
          .goods_picture {
            height: 300px;
          }
        }
        .right {
          flex: 1;
          .goods_name {
            font-size: var(--fontSize16);
            color: var(--color000);
            font-weight: bold;
          }
          .price {
            height: 80px;
            background: var(--colorf6f6f6);
            .straight_pi_jia {
              font-size: var(--fontSize16);
              color: var(--colorfe1818);
              font-weight: bold;
              .discount {
                width: 40px;
                height: 20px;
                background: var(--colorfff2e7);
                border: 1px solid var(--colorfe1818);
                border-radius: 4px;
                font-size: var(--fontSize12);
                color: var(--colorfe1818);
                font-weight: bold;
                text-align: center;
                line-height: 18px;
              }
            }
            .straight_pi {
              font-size: var(--fontSize16);
              color: var(--color000);
              font-weight: bold;
              img {
                width: 60px;
                height: 23px;
              }
              div {
                display: inline-block;
                padding: 5px 10px;
                background: linear-gradient(90deg, #FAC479, #FBE6C7, #FAC479);
                border-radius: 4px;
                font-size: var(--fontSize12);
                color: #945606;
                font-weight: 500;
              }
            }
            .pricing {
              font-size: var(--fontSize12);
              color: var(--color999);
              font-weight: 500;
              span {
                text-decoration: line-through;
              }
            }
          }
          .operation {
            .number {
              font-size: var(--fontSize16);
              color: var(--color999);
              font-weight: 500;
            }
            .add_purchase_order {
              position: relative;
              width: 360px;
              height: 60px;
              background: var(--colored6d00);
              border-radius: 8px;
              font-size: var(--fontSize16);
              color: var(--colorfff);
              font-weight: bold;
              text-align: center;
              line-height: 64px;
              cursor: pointer;
              img {
                position: absolute;
                top: -10px;
                right: 20px;
                width: 80px;
                height: 30px;
              }
            }
            .no_inventory {
              background: var(--colorccc);
            }
          }
        }
      }
      .rich_text {
        .title {
          margin: 50px auto;
          width: 196px;
          height: 28px;
        }
        .body {
          width: 100%;
          height: 100%;
          border: 1px solid var(--colorccc);
        }
      }
    }
  }
</style>
